<template>
  <div>
    <!-- 盒子数量 -->
    <div class="hashPowerBoxTit">
      <div>AI算力盒子数量</div>
      <span>2</span>台
    </div>
    <!-- 条件 -->
    <el-row>
      <el-form :inline="true" :model="params">
        <el-form-item label="选择区域" class="formItemBoxStyle">
          <el-select
            v-model="params.aaaa"
            @change="handleCurrentChange(1)"
            placeholder="全部"
          >
            <el-option label="全部" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择学校" class="formItemBoxStyle">
          <el-select
            v-model="params.aaaa"
            @change="handleCurrentChange(1)"
            placeholder="全部"
          >
            <el-option label="全部" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择考场" class="formItemBoxStyle">
          <el-select
            v-model="params.aaaa"
            @change="handleCurrentChange(1)"
            placeholder="全部"
          >
            <el-option label="全部" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择状态" class="formItemBoxStyle">
          <el-select
            v-model="params.aaaa"
            @change="handleCurrentChange(1)"
            placeholder="全部"
          >
            <el-option label="全部" :value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-button
          icon="el-icon-refresh-right"
          type="primary"
          size="medium"
        ></el-button>
        <el-button type="primary" size="medium">批量升级</el-button>
      </el-form>
    </el-row>
    <!-- 表单 -->
    <el-table
      header-align="center"
      border
      :header-cell-style="{ background: '#DFE6EC' }"
      :data="tableData"
      default-expand-all
      row-key="examId"
      :tree-props="{ children: 'children' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        align="center"
        type="selection"
        width="55"
      ></el-table-column>
      <el-table-column
        type="index"
        align="center"
        width="50"
        label="序号"
      ></el-table-column>
      <el-table-column align="center" prop="a" label="主机名"></el-table-column>
      <el-table-column
        align="center"
        prop="b"
        label="软件版本"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="c"
        label="所在区域"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="d"
        label="所在学校"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="e"
        label="所在考场"
      ></el-table-column>
      <el-table-column align="center" prop="f" label="IP地址"></el-table-column>
      <el-table-column align="center" prop="g" label="状态" width="100">
        <template slot-scope="scope">
          <span class="dotSpan"
            ><i :style="scope.row.g ? 'background:#5AD8A6' : ''"></i
            >{{ scope.row.g ? '在线' : '离线' }}</span
          >
        </template>
      </el-table-column>
      <el-table-column align="center" label="管理" width="100">
        <template slot-scope="scope">
          <el-button type="text" :disabled="!scope.row.g">远程配置</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableDataPagination.pageIndex"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="tableDataPagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableDataPagination.total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: 'hashPowerBox',
  data() {
    return {
      // 条件
      params: {
        aaaa: '',
      },
      tableData: [
        {
          a: 'AM1025-201',
          b: 'VSR6C01',
          c: '番禺区',
          d: '展会中学',
          e: '物理考场',
          f: '192.168.6.175',
          g: true,
          h: '',
        },
        {
          a: 'AM1025-201',
          b: 'VSR6C01',
          c: '番禺区',
          d: '展会中学',
          e: '化学考场',
          f: '192.168.6.175',
          g: false,
          h: '',
        },
      ],
      // 分页
      tableDataPagination: {
        // 当前页
        pageIndex: 1,
        // 页大小
        pageSize: 5,
        // 页数
        pageTotal: 1,
        // 总数量
        total: 0,
      },
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // console.log(`每页 ${val} 条`);
    handleSizeChange(val) {
      this.tableDataPagination.pageSize = val
      // this.sendAsk()
    },
    // console.log(`当前页: ${val}`);
    handleCurrentChange(val) {
      this.tableDataPagination.pageIndex = val
      // this.sendAsk()
    },
  },
}
</script>

<style lang="scss" scoped>
.hashPowerBoxTit {
  margin: 20px 0;
  color: #aaaaaa;
  background: #f8f8f8;
  width: 300px;
  padding: 10px;
  span {
    display: inline-block;
    color: #409eff;
    font-size: 30px;
  }
}
.dotSpan {
  display: block;
  position: relative;
  i {
    width: 10px;
    height: 10px;
    background: #aaaaaa;
    display: inline-block;
    margin-right: 6px;
    border-radius: 50%;
  }
}
</style>
